import gsap, { Expo } from 'gsap';

class InitAnimation {
  heading = (e, flag = '') => {
    if (flag === '') {
      e.className = 'clip on';
    } else {
      e.className = `clip clip${flag} on`;
    }
  };

  intro = (e) => {
    requestAnimationFrame(() => {
      gsap
        .to(e, {
          duration: 0.7,
          opacity: 1,
          translateX: '0',
          ease: Expo.easeOut,
        });
    });
  };

  slider = (e1, e2) => {
    const tl = gsap.timeline({
      defaults: {
        duration: 0.3,
        ease: Expo.easeOut,
      },
    });
    requestAnimationFrame(() => {
      tl
        .to(e1, {
          translateX: '0',
        })
        .to(e2, {
          duration: 0.1,
          opacity: 1,
        }, 0.5)
        .to(e1, {
          translateX: '101%',
        });
    });
  };

  list = (e) => {
    requestAnimationFrame(() => {
      gsap
        .to(e, {
          duration: 1.2,
          translateX: 0,
          opacity: 1,
          stagger: 0.2,
          ease: Expo.easeOut,
        });
    });
  };

  publishedSpAnimation = (e1, e2) => {
    const tlSp = gsap.timeline({
      defaults: {
        duration: 1,
        ease: Expo.easeOut,
      },
    });
    requestAnimationFrame(() => {
      tlSp
        .to(e1, {
          scale: 1,
        })
        .to(e2, {
          scale: 1,
        }, 0.1);
    });
  };

  publishedPcAnimation = (e1, e2) => {
    const tlPc = gsap.timeline({
      defaults: {
        duration: 2,
        ease: Expo.easeOut,
      },
    });
    requestAnimationFrame(() => {
      tlPc
        .to(e1, {
          scale: 0.95,
        })
        .to(e2, {
          scale: 1,
        }, 0.1);
    });
  };
}

export default InitAnimation;
