import React from 'react';
import PropTypes from 'prop-types';
import TransitionLink from 'gatsby-plugin-transition-link';
import gsap, { Expo } from 'gsap';
import Style from '../styles/common/header.module.scss';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.isWork = props.isWork;
    this.transition = props.transition;
    this.current = props.current;
    this.state = {
      isMenu: false,
    };
  }

  toggleMenu = () => {
    const { isMenu } = this.state;
    const nav = document.querySelector('.nav');
    const navBg = document.querySelector('.navBg');
    const menuBtnSpan = Array.from(document.querySelectorAll('.menuBtnSpan'));
    const svg = Array.from(document.querySelectorAll('.logoPath'));
    this.setState({
      isMenu: !isMenu,
    });
    const tlMenu = gsap.timeline({
      defaults: {
        duration: 0.5,
        ease: Expo.easeInOut,
      },
    });
    if (!isMenu) {
      this.menuBtn.className = `spTabVDisBlock ${Style.menuBtn} ${Style.open}`;
      window.requestAnimationFrame(() => {
        tlMenu
          .fromTo(navBg,
            {
              translateX: '-100%',
            },
            {
              translateX: '0',
            })
          .fromTo(nav,
            {
              translateX: '-100%',
            },
            {
              translateX: '0',
            },
            0.05);
      });
      if (this.isWork) {
        menuBtnSpan.forEach((e) => {
          e.style.backgroundColor = '#000';
        });
        svg.forEach((e) => {
          e.style.fill = '#000';
        });
      }
    } else {
      this.menuBtn.className = `spTabVDisBlock ${Style.menuBtn}`;
      window.requestAnimationFrame(() => {
        tlMenu
          .fromTo(nav,
            {
              translateX: '0',
            },
            {
              translateX: '100%',
            })
          .fromTo(navBg,
            {
              translateX: '0',
            },
            {
              translateX: '100%',
            },
            0.05);
      });
      if (this.isWork) {
        menuBtnSpan.forEach((e) => {
          e.style.backgroundColor = '#fff';
        });
        svg.forEach((e) => {
          e.style.fill = '#fff';
        });
      }
    }
  };

  render() {
    return (
      <header className={!this.isWork ? `header ${Style.header} ${this.current}` : `header ${Style.header} ${Style.isWork} ${this.current}`}>
        <div className={`inner ${Style.inner}`}>
          <p className={Style.logo}>
            <TransitionLink to="/" exit={{ length: this.transition.InitTransition.length, trigger: ({ exit }) => this.transition.animation(exit) }} entry={{ delay: this.transition.InitTransition.delay }} aria-label="Top">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 116 24.2"
              >
                <path className="logoPath" d="M9.4,8.1c-2,0-3.4,0.5-4.7,1.8H4.7V0.1L4.7,0H0.1L0,0.1v23.7l0.1,0.1h2.7l0.1-0.1l1-1.3h0.1c1.3,1.2,2.9,1.7,4.7,1.7c4.5,0,8.1-3.5,8.1-8.2C16.7,11.8,13.8,8.1,9.4,8.1 M8.1,20.1c-2.4,0-3.7-1.6-3.7-3.9c0-2.3,1.3-3.9,3.7-3.9c2.4,0,3.9,1.6,3.9,3.9C12.1,18.5,10.5,20.1,8.1,20.1" />
                <path className="logoPath" d="M27.8,8.1c-2.3,0-5.1,0.7-6.7,2.2v0.1L23,13h0.1c1.1-0.9,3-1.4,4.3-1.4c1.6,0,2.8,0.8,2.8,2.5c-3.9,0-10.4,0.1-10.4,5.4c0,3.1,2.6,4.7,5.8,4.7c2,0,3.6-0.8,4.5-1.7h0.1l0.9,1.3l0.1,0.1h3.2l0.1-0.1v-7.7C34.5,10.9,33.3,8.1,27.8,8.1 M30.1,18.1c-0.2,1.7-1.9,3-3.6,3c-1,0-2.2-0.5-2.2-1.7c0-2.1,3.2-2.2,4.7-2.2h1.1l0.1,0.1C30.2,17.2,30.2,17.5,30.1,18.1" />
                <path className="logoPath" d="M39.7,8.6L39.7,8.6l4.5-0.1l0.1,0.1v1.9h0.1c0.6-1.2,2.2-2.3,4.5-2.3c4.8,0,5.7,3.2,5.7,7.3v8.4l-0.1,0.1h-4.6l-0.1-0.1v-7.4c0-1.8,0-4-2.5-4c-2.5,0-2.8,2.1-2.8,4v7.4l-0.1,0.1h-4.6l-0.1-0.1V8.6z" />
                <polygon className="logoPath" points="59.6,0.1 59.7,0 64.3,0 64.4,0.1 64.4,14.1 64.4,14.1 68.3,8.6 68.4,8.5 73.5,8.5 73.5,8.6 68.8,15.4 68.8,15.5 74.5,23.8 74.5,23.9 69.1,23.9 69,23.8 64.4,16.9 64.4,16.9 64.4,23.8 64.3,23.9 59.7,23.9 59.6,23.8" />
                <path className="logoPath" d="M116,11.6l-2.2-1.8l-0.2,0c-0.4,0.2-0.7,0.5-1.2,0.6c-1.3-1.5-3.3-2.3-5.7-2.3c-4.7,0-9.1,3.2-10.2,8c-1.1,4.9,2.1,8,6.8,8c4.7,0,9.1-3.2,10.2-8c0.2-1,0.3-2,0.2-2.8c0.6-0.3,1.2-0.7,1.7-1.1c0.2-0.2,0.4-0.4,0.6-0.6L116,11.6zM108.8,16.2c-0.5,2.3-2.5,4-4.8,4c-2.4,0-3.3-1.7-2.8-4c0.5-2.3,2.5-4,4.8-4C108.4,12.1,109.4,13.9,108.8,16.2" />
                <path className="logoPath" d="M91,19.2c-0.5,0.4-1.1,1-1.8,1.3c-0.5,0.2-0.9,0.2-1.1,0.1c-0.3-0.2-0.4-0.5-0.4-0.8c0-0.3,0.5-1.9,0.5-1.9l1.2-4h4l0.1-0.1l1.2-3.4l-0.1-0.1h-4.1l1.2-4l-0.1-0.1h-4.2l-0.1,0.1l-1.3,4h-2.4l-0.1,0.1l-1.1,3.4l0.1,0.1h2.3l-1,2.9c-0.5,1.7-0.7,2.3-0.8,3.1c-0.2,1.5,0.4,2.6,1.3,3.3c0.7,0.5,1.7,1,3.1,1c2.3,0,3.9-1.5,4.9-2.5c0.2-0.2,0.4-0.4,0.5-0.5l0-0.1l-1.9-1.9H91z" />
              </svg>
            </TransitionLink>
          </p>
          <button
            className={`spTabVDisBlock ${Style.menuBtn}`}
            ref={(node) => { this.menuBtn = node; }}
            onClick={this.toggleMenu}
            type="button"
            aria-label="menu_button"
          >
            <span className="menuBtnSpan" />
            <span className="menuBtnSpan" />
            <span className="menuBtnSpan" />
          </button>
          <nav
            className={`nav ${Style.nav}`}
            ref={(node) => { this.nav = node; }}
          >
            <ul className={Style.list}>
              <li className={Style.item}>
                <TransitionLink to="/services" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>
                  SERVICES
                  <svg
                    className="spTabVDisBlock arrow"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 125 20.4"
                  >
                    <line x1="0" y1="10.2" x2="123.9" y2="10.2" />
                    <polyline points="114.9,19.9 123.9,10.2 114.9,0.5" />
                  </svg>
                </TransitionLink>
              </li>
              <li className={Style.item}>
                <TransitionLink to="/works" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>
                  WORKS
                  <svg
                    className="spTabVDisBlock arrow"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 125 20.4"
                  >
                    <line x1="0" y1="10.2" x2="123.9" y2="10.2" />
                    <polyline points="114.9,19.9 123.9,10.2 114.9,0.5" />
                  </svg>
                </TransitionLink>
              </li>
              <li className={Style.item}>
                <TransitionLink to="/ourteam" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>
                  TEAM
                  <svg
                    className="spTabVDisBlock arrow"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 125 20.4"
                  >
                    <line x1="0" y1="10.2" x2="123.9" y2="10.2" />
                    <polyline points="114.9,19.9 123.9,10.2 114.9,0.5" />
                  </svg>
                </TransitionLink>
              </li>
              <li className={Style.item}>
                <TransitionLink to="/culture" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>
                  CULTURE
                  <svg
                    className="spTabVDisBlock arrow"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 125 20.4"
                  >
                    <line x1="0" y1="10.2" x2="123.9" y2="10.2" />
                    <polyline points="114.9,19.9 123.9,10.2 114.9,0.5" />
                  </svg>
                </TransitionLink>
              </li>
              <li className={Style.item}>
                <TransitionLink to="/about" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>
                  ABOUT
                  <svg
                    className="spTabVDisBlock arrow"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 125 20.4"
                  >
                    <line x1="0" y1="10.2" x2="123.9" y2="10.2" />
                    <polyline points="114.9,19.9 123.9,10.2 114.9,0.5" />
                  </svg>
                </TransitionLink>
              </li>
              {/*<li className={Style.item}>*/}
              {/*  <TransitionLink to="/recruit" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>*/}
              {/*    RECRUIT*/}
              {/*    <svg*/}
              {/*      className="spDisBlock arrow"*/}
              {/*      version="1.1"*/}
              {/*      xmlns="http://www.w3.org/2000/svg"*/}
              {/*      xmlnsXlink="http://www.w3.org/1999/xlink"*/}
              {/*      viewBox="0 0 125 20.4"*/}
              {/*    >*/}
              {/*      <line x1="0" y1="10.2" x2="123.9" y2="10.2" />*/}
              {/*      <polyline points="114.9,19.9 123.9,10.2 114.9,0.5" />*/}
              {/*    </svg>*/}
              {/*  </TransitionLink>*/}
              {/*</li>*/}
              <li className={`${Style.item} ${Style.itemBtn}`}>
                <a href="mailto:info@bankto.co.jp"><span>CONTACT</span></a>
              </li>
            </ul>
          </nav>
          <div
            className={`spTabVDisBlock navBg ${Style.navBg}`}
            ref={(node) => { this.navBg = node; }}
          />
        </div>
      </header>
    );
  }
}

Header.defaultProps = {
  isWork: false,
  current: '',
};

Header.propTypes = {
  isWork: PropTypes.bool,
  transition: PropTypes.object.isRequired,
  current: PropTypes.string,
};

export default Header;
