import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import gsap, { Expo } from 'gsap';
import Style from '../styles/common/cursor.module.scss';

const mapStateToProps = ({ cursorX, cursorY, isTransition }) => ({ cursorX, cursorY, isTransition });

class Cursor extends React.Component {
  constructor(props) {
    super(props);
    const { cursorX, cursorY, isTransition } = props;
    this.cursorX = cursorX;
    this.cursorY = cursorY;
    this.isReduce = false;
    this.isTransition = isTransition;
  }

  componentDidMount() {
    const circleLarge = document.querySelector('.circleLarge');
    const circleSmall = document.querySelector('.circleSmall');
    const targetLink = Array.from(document.querySelectorAll('a'));
    const targetFace = Array.from(document.querySelectorAll('.face'));
    this.init(circleLarge, circleSmall);
    window.addEventListener('mousemove', (e) => { this.onMouseMove(e, circleLarge, circleSmall); });
    targetLink.forEach((e) => {
      e.addEventListener('mouseenter', () => { this.onMouseenter(circleLarge, circleSmall); });
      e.addEventListener('mouseleave', () => { this.onMouseleave(circleLarge, circleSmall); });
    });
    targetFace.forEach((e) => {
      e.addEventListener('mouseenter', () => { this.onMouseenterFace(circleLarge, circleSmall); });
      e.addEventListener('mouseleave', () => { this.onMouseleaveFace(circleLarge, circleSmall); });
    });
    window.addEventListener('mousedown', this.onMouseDown);
  }

  componentWillUnmount() {
    const circleLarge = document.querySelector('.circleLarge');
    const circleSmall = document.querySelector('.circleSmall');
    window.removeEventListener('mousemove', (e) => { this.onMouseMove(e, circleLarge, circleSmall); });
  }

  init = (eLarge, eSmall) => {
    const initELarge = eLarge;
    const initESmall = eSmall;
    if (this.isTransition) window.removeEventListener('mousemove', this.onMouseMove);
    initELarge.style.transform = `translate3d(${this.cursorX - 11}px, ${this.cursorY - 11}px, 0)`;
    initESmall.style.transform = `translate3d(${this.cursorX}px, ${this.cursorY}px, 0)`;
    gsap.set(eLarge, { opacity: 0 });
    gsap.set(eSmall, { opacity: 0 });
    gsap.to(eLarge, {
      duration: 1,
      opacity: 1,
    });
    gsap.to(eSmall, {
      duration: 1,
      opacity: 1,
    });
  };

  onMouseMove = (e, eLarge, eSmall) => {
    if (!this.isReduce) {
      window.requestAnimationFrame(() => {
        gsap.to(eLarge, {
          duration: 0.5,
          translateX: e.clientX - 11,
          translateY: e.clientY - 11,
        });
      });
      window.requestAnimationFrame(() => {
        gsap.to(eSmall, {
          duration: 0.3,
          translateX: e.clientX,
          translateY: e.clientY,
        });
      });
      this.isReduce = true;
      setTimeout(() => { this.isReduce = false; }, 1000 / 30);
    }
  };

  onMouseenter = (eLarge, eSmall) => {
    const enterELarge = eLarge;
    const enterESmall = eSmall;
    enterELarge.style.willChange = 'auto';
    enterESmall.style.willChange = 'auto';
    window.requestAnimationFrame(() => {
      gsap.to(eLarge, {
        duration: 0.25,
        scale: 1.8,
        ease: Expo.easeOut,
      });
    });
    window.requestAnimationFrame(() => {
      gsap.to(eSmall, {
        duration: 0.5,
        scale: 0,
        ease: Expo.easeOut,
      });
    });
  };

  onMouseleave = (eLarge, eSmall) => {
    const leaveELarge = eLarge;
    const leaveESmall = eSmall;
    leaveELarge.style.willChange = 'transform';
    leaveESmall.style.willChange = 'transform';
    window.requestAnimationFrame(() => {
      gsap.to(eLarge, {
        duration: 0.25,
        scale: 1,
        ease: Expo.easeOut,
      });
    });
    window.requestAnimationFrame(() => {
      gsap.to(eSmall, {
        duration: 0.5,
        scale: 1,
        ease: Expo.easeOut,
      });
    });
  };

  onMouseenterFace = (eLarge, eSmall) => {
    const enterFaceELarge = eLarge;
    const enterFaceESmall = eSmall;
    enterFaceELarge.style.willChange = 'auto';
    enterFaceESmall.style.willChange = 'auto';
    enterFaceELarge.style.backgroundColor = '#0210bf';
    window.requestAnimationFrame(() => {
      gsap.to(eLarge, {
        duration: 0.25,
        // scale: 1.8,
        scale: 1,
        ease: Expo.easeOut,
      });
    });
    window.requestAnimationFrame(() => {
      gsap.to(eSmall, {
        duration: 0.5,
        scale: 0,
        ease: Expo.easeOut,
      });
    });
  };

  onMouseleaveFace = (eLarge, eSmall) => {
    const leaveFaceELarge = eLarge;
    const leaveFaceESmall = eSmall;
    leaveFaceELarge.style.willChange = 'transform';
    leaveFaceESmall.style.willChange = 'transform';
    leaveFaceELarge.style.backgroundColor = '#FFFF28';
    window.requestAnimationFrame(() => {
      gsap.to(eLarge, {
        duration: 0.25,
        scale: 1,
        ease: Expo.easeOut,
      });
    });
    window.requestAnimationFrame(() => {
      gsap.to(eSmall, {
        duration: 0.5,
        scale: 1,
        ease: Expo.easeOut,
      });
    });
  };

  onMouseDown = (e) => {
    const { dispatch } = this.props;
    dispatch({
      type: 'CURSOR',
      cursorX: e.clientX,
      cursorY: e.clientY,
    });
    return false;
  };

  render() {
    return (
      <div className={`cursor ${Style.cursor}`}>
        <div className={`circleLarge ${Style.circle} ${Style.circleLarge}`} />
        <div className={`circleSmall ${Style.circle} ${Style.circleSmall}`} />
      </div>
    );
  }
}

Cursor.propTypes = {
  dispatch: PropTypes.func.isRequired,
  cursorX: PropTypes.number.isRequired,
  cursorY: PropTypes.number.isRequired,
  isTransition: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Cursor);
