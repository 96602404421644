import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TransitionPortal } from 'gatsby-plugin-transition-link';
import gsap, { Expo } from 'gsap';
import Style from '../styles/common/transition.module.scss';

const mapStateToProps = ({ isTransition }) => ({ isTransition });

class Transition extends React.Component {
  constructor(props) {
    super(props);
    this.isTransition = props.isTransition;
  }

  exitAnimation = (length) => {
    const { dispatch } = this.props;
    dispatch({
      type: 'TRANSITION',
      isTransition: true,
    });
    const tlExit = gsap.timeline({
      defaults: {
        duration: length / 4,
        ease: Expo.easeInOut,
      },
    });
    requestAnimationFrame(() => {
      tlExit
        .to(this.transition, {
          translateX: '0',
        })
        .to(this.transition, {
          delay: 0.5,
          translateX: '100%',
          onComplete: () => {
            dispatch({
              type: 'TRANSITION',
              isTransition: false,
            });
          },
        });
    });
  };

  entryAnimation = () => {};

  render() {
    const { children } = this.props;
    return (
      <>
        {children}
        <TransitionPortal>
          <div
            className={Style.transition}
            ref={(node) => { this.transition = node; }}
          />
        </TransitionPortal>
      </>
    );
  }
}

Transition.propTypes = {
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
  isTransition: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Transition);
