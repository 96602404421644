import React from 'react';
import PropTypes from 'prop-types';
import { isEdge } from 'react-device-detect';
import Cursor from './cursor';

const Layout = ({ children }) => (
  <div className="layout">
    {!isEdge && (<Cursor />)}
    {children}
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
